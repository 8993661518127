.bigback {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  justify-content: space-between;
}
@media screen and (max-width: 1000px) {
  .bigback {
    /* height: 400px; */
    padding-top: 65px;
    height: auto;
  }
}

.insidebig {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
}
.twocols {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.colone {
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  width: 30%;
}
.cta {
  width: 100%;
  border-radius: 15px;
  background: var(--main-color);
  color: white;
  display: flex;
  transition: 200ms;
  height: 40px;
  margin-top: 20px;
  border: 1px solid transparent;
  align-items: center;
  justify-content: center;
}
.cta:hover {
  background: white;
  color: var(--main-color);
  cursor: pointer;
  border: 1px solid var(--main-color);
}
.coltwo {
  width: 60%;
}
.imageright {
  width: 100%;
  object-fit: contain;
}
.playbtn {
  position: absolute;
  color: white;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.littlePlayIcon {
  border: 1px solid white;
  width: 95px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  padding-left: 5px;
  align-items: center;
  justify-content: center;
  animation: infinite scaler 1500ms;
  font-size: 40px;
  transition: 200ms;
}
.littlePlayIcon:hover {
  background: var(--main-color);
  cursor: pointer;
}
@keyframes scaler {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
.littleiconsleft {
  position: absolute;
  z-index: 11;
}
