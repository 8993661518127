.jineye {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  margin: auto;
}
.eyes {
  position: absolute;
  width: 300px;
  margin-top: -35px;
  margin-left: 17px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  column-gap: 20px;
}
.image {
  width: 300px;
  position: absolute;
}
.eyeone {
  border: 1px solid black;
  width: 28px;
  height: 7px;
  border-radius: 45%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  transform: rotate(-6deg);
}
.eyetwo {
  border: 1px solid black;
  width: 25px;
  transform: rotate(7deg);
  height: 9px;
  border-radius: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.pupil {
  width: 7px;
  height: 7px;
  border-radius: 101px;
  background-color: #000;
}
