.buyerser {
  width: 100%;
}
.eachb {
  width: 100%;
  display: flex;
  align-items: Center;
  justify-content: center;
  margin-top: 20px;
}
.buyerbtn {
  width: 250px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms;
}
.jupiter {
  border: 1px solid #c7f284;
}
.lbank {
  border: 1px solid #fc6;
}
.lbank:hover {
  background: rgba(255, 204, 102, 0.23);
}
.jupiter:hover {
  background: #c8f2842a;
}
.buyerbtn:hover {
  cursor: pointer;
}

.jupiter img {
  margin-right: 10px;
  width: 30px;
}

.lbank img {
  margin-left: 10px;
  width: 40px;
}
