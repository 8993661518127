.card {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 40px auto;
  flex-wrap: wrap;
  font-family: freeman;
}
.descs {
  font-size: 20px;
  margin-top: 20px;
  text-align: center;
}
.toppic {
  width: 200px;
  margin: auto;
}
.toppic img {
  margin: auto;
  width: 100px;
  display: block;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0px 0px 16px -5px red;
}
.brandcl {
  margin: 20px;
  min-width: 110px;
  height: 35px;
  display: flex;
  transition: 200ms;
  align-items: center;
  padding: 0px 10px;
  border-radius: 10px;
  border: 1px solid rgb(0, 0, 34);
  font-size: 15px;
}
.brandcl:hover {
  cursor: pointer;
  background: rgb(0, 0, 34);
  color: white;
}
.brandcl svg {
  margin-right: 10px;
  font-size: 20px;
}
.containbtns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.capart {
  text-align: center;
  width: 100%;
  margin: 20px;
}
@media screen and (max-width: 500px) {
  .capart {
    font-size: 12px;
  }
}

@media screen and (max-width: 400px) {
  .capart {
    font-size: 10px;
  }
}
