.eachvideo {
  height: 300px;
}
.eachvideo img {
  /* width: 100%; */
  /* height: 100%; */
  width: 100%;
  aspect-ratio: 4/2;
  object-fit: contain;
}
.jinth {
  width: 80px;
  height: 80px;
}
.jinth img {
  height: 70%;
  border-radius: 10px;
  object-fit: cover;
  max-width: 80%;
  /* height: 80%; */
}
@media screen and (max-width: 768px) {
  .eachvideo {
    height: auto;
    padding: 20px 0px;
  }
}
