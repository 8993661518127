:root {
  /* --main-color: #ee2524; */
  --main-color: #d5331a;
}
a,
a:visited {
  text-decoration: none;
  color: inherit;
}
@font-face {
  font-family: freeman;
  /* src: url("fonts/Freeman/freemanreg.ttf"); */
  src: local("freemanreg"),
    url(./fonts/Freeman/freemanreg.ttf) format("truetype");
}
body,
html {
  padding: 0px;
  margin: 0px;
}
.app-container {
  width: 80%;
  margin: auto;
}
.slick-dots li {
  width: 80px !important;
  height: 80px !important;

  /* margin: 20px !important; */
}
.copyright {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 13px;
  width: 90%;
  margin: 30px auto 20px;
  line-height: 22px;
}
.slick-dots ul {
  margin: 0px;
  width: 100%;
  padding: 0px;
}
.slick-dots {
  position: relative !important;
  display: flex !important;
  flex-wrap: wrap;
  bottom: 0px !important;
  gap: 40px;
}
.slick-dots ul li {
  border-radius: 10px;
  transition: 200ms;
}
.slick-dots ul li:hover {
  background: rgb(222, 222, 222);
}
li.slick-active {
  transition: 200ms;

  background: var(--main-color) !important;
}
@media screen and (max-width: 768px) {
  .app-container {
    width: 90%;
    margin: auto;
  }
  .slick-dots ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-around;
  }
  .slick-dots {
    position: relative !important;
    display: flex !important;
    flex-wrap: wrap;
    bottom: 0px !important;
    gap: 40px;
  }
}
.slick-arrow {
  display: none !important;
}
