.bigtitle {
  font-family: freeman;
  font-size: 2rem;
  width: 100%;
  text-align: center;
  color: var(--main-color);
}
.howBuy {
  margin-top: 160px;
  /* border: 1px solid gray; */
  border-radius: 2rem;
  padding: 40px 30px;
  box-shadow: 0px 0px 8px -5px black;
  background: rgb(88 189 255 / 1%);

  width: calc(100% - 60px);
  margin: 100px auto 0px;
  font-family: freeman;
}
.dcsc h2 {
  /* color: rgb(170, 170, 170); */
  color: var(--main-color);
  font-size: 1rem;
}
.dcsc p {
  font-size: 12px;
  line-height: 22px;
  font-family: Arial, Helvetica, sans-serif;
  color: gray;
}
