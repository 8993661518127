header {
  background: rgba(0, 0, 0, 0.4);
  z-index: 20;
  position: fixed;
  backdrop-filter: blur(5px);
  width: 100%;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000,
    0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  height: 65px;
}
.lefticon {
  font-family: freeman;
  display: flex;
  align-items: center;
  color: white;
}
.lefticon img {
  display: block;
  margin-right: 10px;
  box-shadow: 0px 0px 13px -2px #ee2524;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.header {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}
.rightbox {
  display: flex;
  height: 100%;
  gap: 20px;
  align-items: center;
}
.buynow {
  box-shadow: 0px 0px 10px -5px #ee2524;
  background: var(--main-color);
  color: white;
  border-radius: 15px;
  height: 35px;
  display: flex;
  align-items: center;
  font-family: freeman;
  justify-content: center;
  width: 100px;
  font-size: 12px;
  border: 1px solid transparent;
  transition: 200ms;
}
.buynow svg {
  margin-left: 5px;
}
.buynow:hover {
  background: #f2482e;
  color: white;
  cursor: pointer;
  /* border: 1px solid var(--main-color); */
}
.downermenu {
  width: 150px;
  height: 100px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  margin-top: 45px;
  top: 0px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-direction: column;
}
.downermenu a {
  width: 80%;
}
.eachmenit {
  background: var(--main-color);
  color: white;
  padding: 5px;
  transition: 200ms;
  height: 20px;
  width: calc(100% - 10px);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-family: freeman;
}
.eachmenit:hover {
  background: #f2482e;
  cursor: pointer;
}
