.partred {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 160px;
  font-family: freeman;
}
.buttonred {
  margin: 20px 0px;
  background: var(--main-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms;
  width: 300px;
  height: 60px;
  border-radius: 2rem;
  font-size: 16px;
  box-shadow: 0px 0px 13px -6px black;
}
.buttonred:hover {
  cursor: pointer;
  background: white;
  color: var(--main-color);
}
.buttonred svg {
  margin-left: 10px;
}
