.coinerfull {
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.containsvg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
}
.containbtner {
  display: flex;
  align-items: Center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}
.coinbtn {
  border: 1px solid rgb(56, 97, 251);
  color: rgb(56, 97, 251);
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  transition: 200ms;
  border-radius: 10px;
  flex-wrap: wrap;
}
.coinon {
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
}
.coinbtn:hover {
  cursor: pointer;
  background: rgb(56, 97, 251);
  color: white;
  /* transform: scale(3); */
  /* font-size: 11px; */
  /* height: 100px; */
}
.coinbtn:hover .coinon {
  display: flex;
}
.svgc path {
  fill: rgb(56, 97, 251);
}
.coinbtn:hover .svgc path {
  fill: white;
}
.svgc {
  width: 20px;
  margin-right: 10px;
}
.cointitle {
  display: flex;
  align-items: center;
}
.coinmarketwidget {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background: rgba(255, 255, 255, 0.3);
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 20;
}
.coincenter {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  background: white;
  width: 300px;

  box-shadow: 0px 0px 12px -7px black;
  padding: 20px;
  flex-wrap: wrap;
}
@media screen and (max-width: 580px) {
  .coincenter {
    /* width: 250px; */
  }
  .coinmarketcap-currency-widget > div {
    min-width: 200px !important;
  }
  .coinmarketcap-currency-widget {
    width: 100px !important;
  }
}
.viewma {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.openm {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(56, 97, 251);
  padding: 5px 10px;
  border-radius: 2rem;
  transition: 200ms;
  color: rgb(56, 97, 251);
}
.openm:hover {
  background: rgb(56, 97, 251);
  color: white;
  cursor: pointer;
}
.svgbig {
  width: 80px;
}
.svgbig path {
  fill: rgb(56, 97, 251);
}
.closer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.clo {
  color: gray;
}
.clo:hover {
  color: red;
  cursor: pointer;
}
